export const MasterWizardAbi = [
  {
    type: "function",
    name: "add",
    inputs: [
      {
        name: "_allocPoint",
        type: "uint256",
        internalType: "uint256"
      },
      {
        name: "_lpToken",
        type: "address",
        internalType: "contract IERC20"
      },
      {
        name: "_depositFeeBP",
        type: "uint16",
        internalType: "uint16"
      },
      {
        name: "_withdrawFeeBP",
        type: "uint16",
        internalType: "uint16"
      },
      {
        name: "_withUpdate",
        type: "bool",
        internalType: "bool"
      },
      {
        name: "_checkDuplicate",
        type: "bool",
        internalType: "bool"
      }
    ],
    outputs: [],
    stateMutability: "nonpayable"
  },
  {
    type: "function",
    name: "claimAllRehypothecationRewards",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable"
  },
  {
    type: "function",
    name: "claimRehypothecationRewards",
    inputs: [
      {
        name: "_pid",
        type: "uint256",
        internalType: "uint256"
      }
    ],
    outputs: [],
    stateMutability: "nonpayable"
  },
  {
    type: "function",
    name: "enableEmergencyWithdraw",
    inputs: [
      {
        name: "_pid",
        type: "uint256",
        internalType: "uint256"
      }
    ],
    outputs: [],
    stateMutability: "nonpayable"
  },
  {
    type: "function",
    name: "getTotalDepositAmount",
    inputs: [
      {
        name: "_pid",
        type: "uint256",
        internalType: "uint256"
      }
    ],
    outputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256"
      }
    ],
    stateMutability: "view"
  },
  {
    type: "function",
    name: "poolInfo",
    inputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256"
      }
    ],
    outputs: [
      {
        name: "lpToken",
        type: "address",
        internalType: "contract IERC20"
      },
      {
        name: "allocPoint",
        type: "uint256",
        internalType: "uint256"
      },
      {
        name: "lastRewardBlock",
        type: "uint256",
        internalType: "uint256"
      },
      {
        name: "accRewardTokensPerShare",
        type: "uint256",
        internalType: "uint256"
      },
      {
        name: "depositFeeBP",
        type: "uint16",
        internalType: "uint16"
      },
      {
        name: "withdrawFeeBP",
        type: "uint16",
        internalType: "uint16"
      }
    ],
    stateMutability: "view"
  },
  {
    type: "function",
    name: "poolLength",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256"
      }
    ],
    stateMutability: "view"
  },
  {
    type: "function",
    name: "rehypothecations",
    inputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256"
      }
    ],
    outputs: [
      {
        name: "",
        type: "address",
        internalType: "contract RehypothecationProtocol"
      }
    ],
    stateMutability: "view"
  },
  {
    type: "function",
    name: "rewardTokenAmountPerBlock",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256"
      }
    ],
    stateMutability: "view"
  },
  {
    type: "function",
    name: "set",
    inputs: [
      {
        name: "_pid",
        type: "uint256",
        internalType: "uint256"
      },
      {
        name: "_allocPoint",
        type: "uint256",
        internalType: "uint256"
      },
      {
        name: "_depositFeeBP",
        type: "uint16",
        internalType: "uint16"
      },
      {
        name: "_withdrawFeeBP",
        type: "uint16",
        internalType: "uint16"
      },
      {
        name: "_withUpdate",
        type: "bool",
        internalType: "bool"
      }
    ],
    outputs: [],
    stateMutability: "nonpayable"
  },
  {
    type: "function",
    name: "updateEmissionRate",
    inputs: [
      {
        name: "_rewardTokensAmountPerBlock",
        type: "uint256",
        internalType: "uint256"
      }
    ],
    outputs: [],
    stateMutability: "nonpayable"
  }
] as const;

export const IRehypothecationProtocolAbi = [
  {
    type: "function",
    name: "claimRewards",
    inputs: [
      {
        name: "feeAddress",
        type: "address",
        internalType: "address"
      }
    ],
    outputs: [
      {
        name: "",
        type: "bool",
        internalType: "bool"
      }
    ],
    stateMutability: "view"
  },
  {
    type: "function",
    name: "getTotalDepositAmount",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256"
      }
    ],
    stateMutability: "view"
  },
  {
    type: "function",
    name: "name",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "string",
        internalType: "string"
      }
    ],
    stateMutability: "nonpayable"
  }
] as const;

export const IUniswapV2PairAbi = [
  {
    type: "function",
    name: "token0",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "address",
        internalType: "address"
      }
    ],
    stateMutability: "view"
  },
  {
    type: "function",
    name: "token1",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "address",
        internalType: "address"
      }
    ],
    stateMutability: "view"
  }
] as const;
export const PulseXv1MasterChefAbi = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_pid",
        type: "uint256"
      },
      {
        internalType: "address",
        name: "_user",
        type: "address"
      }
    ],
    name: "pendingInc",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  }
] as const;
