import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Web3ModalProvider } from './Web3ModalProvider';
import Home from './Home';
import './App.css';

function App() {
  return (
    <Web3ModalProvider>
      
      <header className='bg-green-950 flex flex-row p-2'>
        <div className='flex flex-row items-center w-full text-xl gap-2'>
          <img alt="Logo" src="/favicon.ico" className="w-10 h-10" />
          <div className='grow'>
            <span className="font-1 text-sm sm:text-xl">Master Wizard Configuration</span>
          </div>
          <w3m-button size='sm' balance='show'/>
        </div>
      </header>
      <section className='flex flex-row justify-center p-2'>
        <Home />
      </section>
      <ToastContainer position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </Web3ModalProvider>
  );
}

export default App;




