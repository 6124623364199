import { readContract, readContracts } from '@wagmi/core';
import { useAccount, usePublicClient, useReadContracts } from 'wagmi';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { erc20Abi, /*, parseUnits*/ formatUnits } from 'viem';
import { Args, Web3Button/*, estimateAndSend*/ } from './Web3Button';
import { MasterWizardAbi, IRehypothecationProtocolAbi, IUniswapV2PairAbi, PulseXv1MasterChefAbi } from './abi/abis';
import { config } from './Web3ModalProvider';
import { LiquidLoansStabilityPoolAbi } from './abi/LiquidLoans';
import { LiquityStabilityPoolAbi } from './abi/Liquity';

type Address = `0x${string}`;
const ZeroAddress = '0x0000000000000000000000000000000000000000';
// const tokenContractAddress = '0x5eEE34DA80bc55C4Ba0495Fc6f9568458fA5e92D';
const devAddress = '0x7035D3859E61B1F4F54DD6E82F8F10f11A32A095';
const feeAddress = '0x7035D3859E61B1F4F54DD6E82F8F10f11A32A095';
const tokenAddressINC = '0x2fa878Ab3F87CC1C9737Fc071108F904c0B0C95d';
const tokenAddressLOAN = '0x9159f1D2a9f51998Fc9Ab03fbd8f265ab14A1b3B';
const tokenAddressEARN = '0xb513038BbFdF9D40B676F41606f4F61D4b02c4A2';
const tokenAddressFLEX = '0x9c6fA17D92898B684676993828143596894AA2A6';
const masterChefContractAddress: Address = '0x4d5805F5E8ebf99B8A7653d59494006Ca877CAcF';
const rewardTokenAddress = '0xFC3824E8d68988D66c23baeD7bA094D8B0DBd9a6';
const rewardTokenSymbol = 'MAGIX';

type PoolInfo = {
  tokenAddress: Address;
  allocPoint: bigint;
  lastRewardBlock: bigint;
  accRewardTokensPerShare: bigint;
  depositFeeBP: bigint;
  withdrawFeeBP: bigint;
};

type AmountProps = {
  amount: bigint | undefined;
  displayDecimals?: bigint;
  // isLoading?: boolean;
}
function Amount({ amount, displayDecimals = 4n }: AmountProps) {
  if (typeof amount == "bigint") {
    const precision = 10n ** (18n - displayDecimals);
    amount = amount / precision * precision;
  }
  return <>
    {typeof amount == "bigint" ? formatUnits(amount, 18) : "N/A"}
  </>;
}

type PoolPanelProps = {
  masterChefContractAddress: Address;
  pid?: number,
  poolInfo?: PoolInfo;
  poolInfoRefetch: () => void;
  revision: number;
};
function PoolPanel({ masterChefContractAddress, pid, poolInfo, poolInfoRefetch, revision }: PoolPanelProps) {
  const addMode = pid === undefined;
  const showDetails = false;
  const pidBI = (pid && pid >= 0) ? BigInt(pid) : 999999999n;
  let { tokenAddress, allocPoint, lastRewardBlock, accRewardTokensPerShare, depositFeeBP, withdrawFeeBP } = poolInfo ?? {};

  const { data: tokenInfo, isLoading: tokenInfoIsLoading } = useReadContracts({
    allowFailure: true,
    scopeKey: `tokenInfo-${revision}`,
    contracts: [
      {
        address: tokenAddress,
        abi: erc20Abi,
        functionName: 'symbol',
        args: [],
      },
      {
        address: tokenAddress,
        abi: IUniswapV2PairAbi,
        functionName: 'token0',
        args: [],
      },
      {
        address: tokenAddress,
        abi: IUniswapV2PairAbi,
        functionName: 'token1',
        args: [],
      },
      {
        address: masterChefContractAddress,
        abi: MasterWizardAbi,
        functionName: 'rehypothecations',
        args: [pidBI],
      },
      {
        address: masterChefContractAddress,
        abi: MasterWizardAbi,
        functionName: 'getTotalDepositAmount',
        args: [pidBI],
      },
    ],
    // enabled: !addMode && !!tokenAddress, // FIXME
  });

  const tokenName = tokenInfo?.[0]?.result;
  const tokenLPToken0 = tokenInfo?.[1]?.result as Address | undefined;
  const tokenLPToken1 = tokenInfo?.[2]?.result as Address | undefined;
  const rhypoContractAddress = tokenInfo?.[3]?.result as Address | undefined;
  const stakedAmount = tokenInfo?.[4]?.result as bigint | undefined;

  const { data: lpTokenInfo, isLoading: lpTokenInfoIsLoading } = useReadContracts({
    allowFailure: true,
    scopeKey: `lpTokenInfo-${revision}`,
    contracts: [
      {
        address: tokenLPToken0,
        abi: erc20Abi,
        functionName: 'symbol',
        args: [],
      },
      {
        address: tokenLPToken1,
        abi: erc20Abi,
        functionName: 'symbol',
        args: [],
      },
    ],
    // enabled: !addMode && !!tokenLPToken0 && !!tokenLPToken1, // FIXME
  });

  const { data: rhypoInfo, isLoading: rhypoInfoIsLoading } = useReadContracts({
    allowFailure: true,
    scopeKey: `rhypoInfo-${revision}`,
    contracts: [
      {
        address: rhypoContractAddress,
        abi: IRehypothecationProtocolAbi,
        functionName: 'getTotalDepositAmount',
        args: [],
      },
      {
        address: rhypoContractAddress,
        abi: IRehypothecationProtocolAbi,
        functionName: 'name',
        args: [],
      },
    ],
    // enabled: !addMode, // FIXME
  });

  const [rhypoHasRewards, setRhypoHasRewards] = useState<undefined | boolean>();
  useEffect(() => {
    async function read() {
      if (!rhypoContractAddress)
        return;

      try {
        const result = await readContract(config, {
          address: rhypoContractAddress,
          account: masterChefContractAddress,
          abi: IRehypothecationProtocolAbi,
          functionName: 'claimRewards',
          args: [feeAddress],
        });
        setRhypoHasRewards(result as boolean);
      }
      catch (err) {
        setRhypoHasRewards(false);
      }
    }
    read();
  }, [masterChefContractAddress, rhypoContractAddress, revision]);

  const tokenLPToken0Symbol = lpTokenInfo?.[0]?.result as string | undefined; // FIXME
  const tokenLPToken1Symbol = lpTokenInfo?.[1]?.result as string | undefined; // FIXME
  const rhypoTotalDepositAmount = rhypoInfo?.[0]?.result as bigint | undefined; // FIXME
  const rhypoName = rhypoInfo?.[1]?.result as string | undefined; // FIXME

  const [rhypoDetails, setRhypoDetails] = useState<undefined | string>();
  useEffect(() => {
    async function read() {
      if (!rhypoContractAddress || !rhypoName)
        return;

      try {
        setRhypoDetails(await queryRhypoDetails(rhypoName, rhypoContractAddress));
      }
      catch (err: any) {
        setRhypoDetails(err.shortMessage ?? `${err}`);
      }

    }
    read();
  }, [masterChefContractAddress, rhypoName, rhypoContractAddress, revision]);

  const [newTokenAddress, setNewTokenAddress] = useState(addMode ? "0x" : tokenAddress);
  const [newAllocPoint, setNewAllocPoint] = useState(addMode ? 1 : allocPoint);
  const [newDepositFeeBP, setNewDepositFeeBP] = useState(addMode ? 0 : depositFeeBP);
  const [newWithdrawFeeBP, setNewWithdrawFeeBP] = useState(addMode ? 0 : withdrawFeeBP);
  const [newRhypoContractAddress, setNewRhypoContractAddress] = useState(addMode ? ZeroAddress : '');

  useEffect(() => {
    if (rhypoContractAddress && newRhypoContractAddress === '')
      setNewRhypoContractAddress(rhypoContractAddress);
  }, [rhypoContractAddress, newRhypoContractAddress]);

  return <div className={`border-2 border-green-900 bg-gradient-to-b from-green-950 p-3 rounded-xl${allocPoint === 0n ? ' bg-gray-700' : ''}`}>
    <div className='grid grid-cols-[auto_auto] gap-2'>
      <div className='col-span-2 flex flex-row'>
        <div className='label text-bold text-xl grow'>
          Pool #{`${pid}`}{' '}
          {addMode ?
            <span className='text-blue-500'>New</span>
            :
            <>
              {allocPoint === 0n ? <span className='text-orange-500'>{' '}INACTIVE</span> : ''}
              {tokenInfoIsLoading || lpTokenInfoIsLoading || rhypoInfoIsLoading ?
                <div>...</div>
                : <>
                  {' '}{tokenName ?? "No name?"}
                  {tokenLPToken0Symbol &&
                    <>{' '}{tokenLPToken0Symbol}/{tokenLPToken1Symbol}</>
                  }
                </>
              }
            </>
          }
        </div>
        <div className='col-span-2 flex flex-row justify-end'>
          {addMode ? <AddPoolButton /> : <SetPoolPropertiesButton />}
        </div>
      </div>

      <div className='label'>
        Staked token address:
      </div>
      <input className='input w-[25rem]' disabled={!addMode} type="text" min="0" value={newTokenAddress} onChange={ev => setNewTokenAddress(ev.target.value as Address)} />

      <div className='label'>
        Allocation points:
      </div>
      <div className='value flex flex-row gap-2 items-center'>
        <input className='input text-center w-24' type="number" min="0" value={Number(newAllocPoint)} onChange={ev => setNewAllocPoint(parseFloat(ev.target.value))} />
        <span>[100 = 1X multiplier]</span>
      </div>

      <div className='label'>
        Deposit/withdraw fee:
      </div>
      <div className='value flex flex-row gap-2 items-center'>
        <input className='input text-center w-16' type="number" min="0" value={`${newDepositFeeBP}`} onChange={ev => setNewDepositFeeBP(parseFloat(ev.target.value))} />
        <span>/</span>
        <input className='input text-center w-16' type="number" min="0" value={`${newWithdrawFeeBP}`} onChange={ev => setNewWithdrawFeeBP(parseFloat(ev.target.value))} />
        <span>[1/100 of %]</span>
      </div>

      {!addMode && <>
        <div className='label'>
          Last reward block:
        </div>
        <div className='value'>
          {lastRewardBlock ? Number(lastRewardBlock) : '-'}
        </div>

        {showDetails &&
          <div className='col-span-2 flex flex-row items-center gap-2'>
            <div className='label'>
              Acc. tokens per share: <Amount amount={accRewardTokensPerShare} />
            </div>
            {' '}
            <div className='value'>Staked amount: <Amount amount={stakedAmount} displayDecimals={6n} /></div>
          </div>
        }
      </>}


      {(addMode || rhypoContractAddress !== ZeroAddress) && <>
        <div className='label'>
          Rehypothecation:
        </div>
        <div className='flex flex-col gap-2'>
          {!addMode && <div>{rhypoName ?? "(no name?)"}</div>}
          {!addMode && <div>{rhypoDetails}</div>}
          {addMode ?
            <input className='input w-[25rem]' disabled={!addMode} type="text" min="0" value={newRhypoContractAddress} onChange={ev => setNewRhypoContractAddress(ev.target.value)} />
            :
            <>{showDetails && <code>{rhypoContractAddress}</code>}</>
          }
          {(!addMode && !!rhypoContractAddress && rhypoContractAddress !== ZeroAddress) && <>
            <div>Total deposit: <Amount amount={rhypoTotalDepositAmount} displayDecimals={6n} /></div>
            {/* <div>Rewards: {rhypoHasRewards ? "yes" : "no"}</div> */}
            <div className='flex flex-row gap-2 items-center'>
              <ClaimButton disabled={!rhypoHasRewards} />
              <EnableEmergencyWithdrawButton />
            </div>
          </>
          }
        </div>
      </>}
    </div>
  </div>;

  function AddPoolButton() {
    return <Web3Button
      className="w-24"
      contractAddress={masterChefContractAddress}
      contractAbi={MasterWizardAbi}
      action={async ({ estimateAndSend/*, publicClient, walletClient*/ }) => {
        const args = [
          newAllocPoint,
          newTokenAddress,
          newDepositFeeBP ?? depositFeeBP,
          newWithdrawFeeBP ?? withdrawFeeBP,
          true, // _withUpdate
          true, // _checkDuplicate
        ];
        console.log("MasterWizard.add", args);
        const promise = estimateAndSend("add", args);
        toast.promise(promise, { pending: `Adding new pool...` });
        return await promise;
      }}
      // disabled={!account?.address || !(userBalance > 0n)}
      onError={async (err: any) => {
        console.log(err);
        toast.error(`Failed to add pool: ${err.shortMessage ?? err}`, { delay: 10 });
        poolInfoRefetch();
      }}
      onSuccess={async () => {
        toast(`New pool added`);
        poolInfoRefetch();
      }}
    >
      Add
    </Web3Button>;
  }

  function SetPoolPropertiesButton() {
    return <Web3Button
      className="w-24"
      contractAddress={masterChefContractAddress}
      contractAbi={MasterWizardAbi}
      action={async ({ estimateAndSend/*, publicClient, walletClient*/ }) => {
        const args = [
          pid,
          newAllocPoint ?? allocPoint,
          newDepositFeeBP ?? depositFeeBP,
          newWithdrawFeeBP ?? withdrawFeeBP,
          true,
        ];
        console.log("MasterWizard.set", args);
        const promise = estimateAndSend("set", args);
        toast.promise(promise, { pending: `Changing attributes of pool #${pid}...` });
        return await promise;
      }}
      // disabled={!account?.address || !(userBalance > 0n)}
      onError={async (err: any) => {
        console.log(err);
        toast.error(`Failed to set pool #${pid} attributes: ${err.shortMessage ?? err}`, { delay: 10 });
        poolInfoRefetch();
      }}
      onSuccess={async () => {
        toast(`Pool #${pid} attributes set`);
        poolInfoRefetch();
      }}
    >
      Set
    </Web3Button>;
  }

  type ClaimButtonProps = {
    disabled: boolean;
  }
  function ClaimButton({ disabled }: ClaimButtonProps) {
    return <Web3Button
      className="w-32"
      contractAddress={masterChefContractAddress}
      contractAbi={MasterWizardAbi}
      disabled={disabled}
      action={async ({ estimateAndSend/*, publicClient, walletClient*/ }) => {
        const args = [
          pid
        ];
        console.log("MasterWizard.claimRehypothecationRewards", args);
        const promise = estimateAndSend("claimRehypothecationRewards", args);
        toast.promise(promise, { pending: `Claiming rehypothecation rewards for pool #${pid}...` });
        return await promise;
      }}
      onError={async (err: any) => {
        console.log(err);
        toast.error(`Failed to claim rehypothecation rewards for pool #${pid}: ${err.shortMessage ?? err}`, { delay: 10 });
        // incInfoRefetch();
      }}
      onSuccess={async () => {
        toast(`Rehypothecation rewards #${pid} claimed`);
        // incInfoRefetch();
      }}
    >
      Claim
    </Web3Button>;
  }

  function EnableEmergencyWithdrawButton() {
    return <Web3Button
      className="w-32"
      contractAddress={masterChefContractAddress}
      contractAbi={MasterWizardAbi}
      action={async ({ estimateAndSend/*, publicClient, walletClient*/ }) => {
        const args = [
          pid
        ];
        console.log("MasterWizard.enableEmergencyWithdraw", args);
        const promise = estimateAndSend("enableEmergencyWithdraw", args);
        toast.promise(promise, { pending: `Enabling emergency withdraw for pool #${pid}...` });
        return await promise;
      }}
      onError={async (err: any) => {
        console.log(err);
        toast.error(`Failed to enabled emergency claim for pool #${pid}: ${err.shortMessage ?? err}`, { delay: 10 });
      }}
      onSuccess={async () => {
        toast(`Emergency claim for pool #${pid} enabled`);
      }}
    >
      Enable 🚑💰
    </Web3Button>;
  }
}

async function queryRhypoDetails(rhypoName: string, rhypoContractAddress: Address): Promise<string> {
  if (rhypoName.includes("MasterChef-PulseXv1")) {
    let pid = 99999n;
    if (rhypoName.includes("WPLS/DAI")) pid = 1n;
    if (rhypoName.includes("WPLS/USDC")) pid = 2n;
    if (rhypoName.includes("WPLS/USDT")) pid = 5n;
    const result = await readContract(config, {
      address: '0xB2Ca4A66d3e57a5a9A12043B6bAD28249fE302d4',
      abi: PulseXv1MasterChefAbi,
      functionName: 'pendingInc',
      args: [pid, rhypoContractAddress],
    });
    return `#${pid} Pending ${Math.ceil(Number(result) / 1e18 * 10000) / 10000} INC`;
  }

  if (rhypoName === "MasterWizard.LiquidLoans.StabilityPool") {
    const result = await readContracts(config, {
      contracts: [
        {
          address: '0x7bFD406632483ad00c6EdF655E04De91A96f84bc',
          abi: LiquidLoansStabilityPoolAbi,
          functionName: 'getDepositorLOANGain',
          args: [rhypoContractAddress],
        },
        {
          address: '0x7bFD406632483ad00c6EdF655E04De91A96f84bc',
          abi: LiquidLoansStabilityPoolAbi,
          functionName: 'getDepositorPLSGain',
          args: [rhypoContractAddress],
        },
      ]
    });
    return `Pending ${Math.ceil(Number(result[0].result) / 1e18)} LOAN, ${Math.ceil(Number(result[1].result) / 1e18)} PLS`;
  }

  if (rhypoName === "MasterWizard.PowerCityEarn.StabilityPool"
    || rhypoName === "MasterWizard.PowerCityFlex.StabilityPool"
  ) {
    const isEARN = rhypoName === "MasterWizard.PowerCityEarn.StabilityPool";
    const address = isEARN ? '0x02E842db8d6C78D17cF8146009Fb864094d95319' : '0x271F576fD6DE465231320a0F9997ACb0C8b97e07';
    const token1 = isEARN ? 'EARN' : 'FLEX';
    const token2 = isEARN ? 'PLSX' : 'HEX';
    const result = await readContracts(config, {
      contracts: [
        {
          address,
          abi: LiquityStabilityPoolAbi,
          functionName: 'getDepositorLQTYGain',
          args: [rhypoContractAddress],
        },
        {
          address,
          abi: LiquityStabilityPoolAbi,
          functionName: 'getDepositorETHGain',
          args: [rhypoContractAddress],
        },
      ]
    });
    return `Pending ${Math.ceil(Number(result[0].result) / 1e18 * 1e6) / 1e6} ${token1}, ${Math.ceil(Number(result[1].result) / 1e6 * 1e18) / 1e6} ${token2}`;
  }

  return "";
}

function ClaimAllButton() {
  return <Web3Button
    className="w-32"
    contractAddress={masterChefContractAddress}
    contractAbi={MasterWizardAbi}
    action={async ({ estimateAndSend/*, publicClient, walletClient*/ }) => {
      const args: Args = [];
      console.log("MasterWizard.claimAllRehypothecationRewards", args);
      const promise = estimateAndSend("claimAllRehypothecationRewards", args);
      toast.promise(promise, { pending: `Claiming all rehypothecation rewards...` });
      return await promise;
    }}
    onError={async (err: any) => {
      console.log(err);
      toast.error(`Failed to claim all rehypothecation rewards: ${err.shortMessage ?? err}`, { delay: 10 });
    }}
    onSuccess={async () => {
      toast(`Claiming all rehypothecation rewards succeed`);
    }}
  >
    Claim all
  </Web3Button>;
}

export default function Home() {
  const account = useAccount();
  const publicClient = usePublicClient();

  const [poolsIsFetching, setPoolsIsFetching] = useState(false);
  const [poolsRevision, setPoolsRevision] = useState(0);
  const [pools, setPools] = useState<PoolInfo[]>([]);

  const { data: globalInfo/*, isLoading: globalInfoIsLoading*/, refetch: globalInfoRefetch } = useReadContracts({
    allowFailure: true,
    contracts: [
      {
        address: masterChefContractAddress,
        abi: MasterWizardAbi,
        functionName: 'rewardTokenAmountPerBlock',
        args: [],
      },
      {
        address: rewardTokenAddress,
        abi: erc20Abi,
        functionName: 'balanceOf',
        args: [devAddress],
      },
      {
        address: tokenAddressINC,
        abi: erc20Abi,
        functionName: 'balanceOf',
        args: [feeAddress],
      },
      {
        address: tokenAddressLOAN,
        abi: erc20Abi,
        functionName: 'balanceOf',
        args: [feeAddress],
      },
      {
        address: tokenAddressEARN,
        abi: erc20Abi,
        functionName: 'balanceOf',
        args: [feeAddress],
      },
      {
        address: tokenAddressFLEX,
        abi: erc20Abi,
        functionName: 'balanceOf',
        args: [feeAddress],
      },
    ],
  });

  const emission = globalInfo?.[0]?.result as bigint | undefined; // FIXME
  const devAddressRewardAmount = globalInfo?.[1]?.result as bigint | undefined; // FIXME
  const feeAddressAmountINC = globalInfo?.[2]?.result as bigint | undefined; // FIXME
  const feeAddressAmountLOAN = globalInfo?.[3]?.result as bigint | undefined; // FIXME
  const feeAddressAmountEARN = globalInfo?.[4]?.result as bigint | undefined; // FIXME
  const feeAddressAmountFLEX = globalInfo?.[5]?.result as bigint | undefined; // FIXME

  useEffect(() => {
    async function fetch() {
      if (!publicClient)
        return;
      setPoolsIsFetching(true);
      try {
        const poolLength = await publicClient.readContract({
          address: masterChefContractAddress,
          abi: MasterWizardAbi,
          functionName: 'poolLength',
        }) as bigint | undefined; // FIXME:

        if (poolLength && poolLength > 0n) {
          type Call = {
            address: Address,
            abi: typeof MasterWizardAbi,
            functionName: string,
            args: Args,
          }
          const calls: Call[] = [];
          for (let pid = 0n; pid < poolLength; pid += 1n) {
            calls.push({
              address: masterChefContractAddress,
              abi: MasterWizardAbi,
              functionName: 'poolInfo',
              args: [pid],
            });
          };

          const poolData = await readContracts(config, {
            contracts: calls,
            allowFailure: true,
          });

          const poolDataResults = poolData.map(_ => {
            const result = _.result as unknown as (Address | string | bigint)[];
            return {
              tokenAddress: (result?.[0]) as Address,
              allocPoint: result?.[1] as bigint,
              lastRewardBlock: result?.[2] as bigint,
              accRewardTokensPerShare: result?.[3] as bigint,
              depositFeeBP: result?.[4] as bigint,
              withdrawFeeBP: result?.[5] as bigint,
            };
          });
          setPools(poolDataResults);
        }
        else {
          setPools([]);
        }
      }
      finally {
        setPoolsIsFetching(false);
      }
    }
    fetch();
  }, [account?.isConnected, publicClient, poolsRevision]);

  function poolInfoRefetch() {
    globalInfoRefetch();
    console.log("Pool update requested");
    setPoolsRevision(prev => prev + 1);
  }

  const [newEmission, setNewEmission] = useState<string>('');
  useEffect(() => {
    if (newEmission === '' && emission !== undefined)
      setNewEmission(`${Number(emission) / 1e18}`);
  }, [emission, newEmission]);

  function UpdateEmissionRateButton() {
    return <Web3Button
      className="w-24 !inline"
      contractAddress={masterChefContractAddress}
      contractAbi={MasterWizardAbi}
      action={async ({ estimateAndSend/*, publicClient, walletClient*/ }) => {
        const amount = BigInt(Math.round(parseFloat(newEmission!) * 1e18));
        if(amount <= 0n) {
          throw new Error(`Invalid emission value '${newEmission}'`);
        }
        const args: Args = [amount];
        console.log("MasterWizard.updateEmissionRate", args);
        const promise = estimateAndSend("updateEmissionRate", args);
        toast.promise(promise, { pending: `Changing emission rate...` });
        return await promise;
      }}
      onError={async (err: any) => {
        console.log(err);
        toast.error(`Failed to change emission rate: ${err.shortMessage ?? err}`, { delay: 10 });
        globalInfoRefetch();
      }}
      onSuccess={async () => {
        toast(`Changing emission rate succeed`);
        globalInfoRefetch();
      }}
    >
      Change
    </Web3Button>;
  };

  return (
    account?.isConnected ?
      <div className='flex flex-col gap-3 w-full'>
        <div className='flex flex-row gap-2 items-center'>
          <div className='grow'>
            Dev: <Amount amount={devAddressRewardAmount} displayDecimals={4n} /> {rewardTokenSymbol}
            {' | '}Fee: <Amount amount={feeAddressAmountINC} displayDecimals={4n} /> INC
            {' - '}<Amount amount={feeAddressAmountLOAN} displayDecimals={4n} /> LOAN
            {' - '}<Amount amount={feeAddressAmountEARN} displayDecimals={4n} /> EARN
            {' - '}<Amount amount={feeAddressAmountFLEX} displayDecimals={4n} /> FLEX
            {' | '}Emission:
            {' '}<input className='input text-right w-[5rem]' type="number" value={newEmission} onChange={ev => setNewEmission(ev.target.value)}></input> {rewardTokenSymbol}/block
            {' '}<UpdateEmissionRateButton />
          </div>
          <ClaimAllButton />
          <button className={`btn w-24${poolsIsFetching ? ' bg-animate-barberpole' : ''}`} onClick={poolInfoRefetch}>Refresh</button>
        </div>
        <div className='grid grid-cols-fluid gap-3 w-full'>
          {pools.map((poolInfo, pid) => {
            return <PoolPanel
              pid={pid}
              key={pid}
              poolInfo={poolInfo}
              masterChefContractAddress={masterChefContractAddress}
              poolInfoRefetch={poolInfoRefetch}
              revision={poolsRevision}
            />
          })}

          <PoolPanel
            masterChefContractAddress={masterChefContractAddress}
            poolInfoRefetch={poolInfoRefetch}
            revision={poolsRevision}
          />
        </div>

      </div >
      :
      <div className='btn !bg-red-600'>Please connect your wallet first.</div>
  );
}
