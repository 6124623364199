import React from "react";

import { createWeb3Modal } from '@web3modal/wagmi/react';
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config';

import { WagmiProvider } from 'wagmi';
import { pulsechain, pulsechainV4 } from 'wagmi/chains';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// 0. Setup queryClient
const queryClient = new QueryClient();

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = '000808b6730ff2bd38cdba258000000';

// 2. Create wagmiConfig
const metadata = {
  name: 'Hocus Pocus Master Wizard Configuration',
  description: 'Hocus Pocus Master Wizard Configuration dApp',
  url: 'https://app.houcs.po', // origin must match your domain & subdomain
  icons: ['https://app.hocuspocus.finance/favicon.ico']
};

const chains = [
  pulsechain,
  pulsechainV4,
] as const

export const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  //   ...wagmiOptions // Optional - Override createConfig parameters
});

// 3. Create modal
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true // Optional - false as default
})

type Props = { children: React.ReactNode };

export const Web3ModalProvider: React.FC<Props> = ({ children }) => {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  )
}